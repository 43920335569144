@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&family=VT323&display=swap');
:root {
  box-sizing: border-box;
  font-size: 62.5%;
  --grey-color: rgb(115, 115, 115);
  --onLoadAnimationTime: 1.2s;
  color: var(--grey-color);
  text-align: justify;
}
#app {
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

html {
  /* background: linear-gradient(70deg, rgb(129, 0, 194), rgb(216, 2, 130)); */
  background: linear-gradient(70deg, rgb(0, 45, 194), rgb(9, 153, 232));
}
#home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 75px 0;
}
#home * {
  margin: 0;
  font-size: 3rem;
  font-weight: 300;
  color: white;
  text-align: center;
}
#home h1 {
  text-align: center;
  font-size: 4rem;
  font-weight: 600;
  transform-origin: bottom left;
  /* transform: rotate(-15deg); */
  animation: enterRight .75s linear;
}
@keyframes enterRight {
  0% {
    transform: translate(700px, 0) rotate(0);
  }
  50%, 100% {
    transform: translate(0, 0) rotate(0);
  }
  75% {
    transform: translate(-20px, 0) rotate(-15deg);
  }
}
#home h2 {
  transform-origin: top right;
  /* transform: rotate(-15deg); */
  animation: enterLeft .75s linear;
}
@keyframes enterLeft {
  0% {
    transform: translate(-700px, 0) rotate(0);
  }
  50%, 100% {
    transform: translate(0, 0) rotate(0);
  }
  75% {
    transform: translate(20px, 0) rotate(-15deg);
  }
}
.underLineStyle {
  height: 2px;
  border-radius: 1px;
  width: 130px;
  max-width: 100%;
  background: linear-gradient(90deg, rgb(216, 2, 130), rgb(129, 0, 194), rgb(0, 10, 194), rgb(0, 219, 219))
}

main {
  text-align: center;
  margin: 0 30px;
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 2rem;
  border-radius: 5px;
}
#projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sectionTitle {
  font-size: 3rem;
  margin: 10px 0 5px 0;
}
.individualProject:nth-child(2n) {
  flex-direction: row-reverse;
}
.individualProject:last-child {
  border-bottom: none;
}
.individualProject {
  margin: 0 40px;
  padding: 40px 20px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px var(--grey-color) solid;
}
.projectText {
  flex: 0 1 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  text-align: justify;
}
.projectText > p {
  text-align-last: center;
}
.technologies {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
summary {
  cursor: pointer;
}
.hiddenForFadeIn {
  opacity: 0;
}
.fadeIn {
  animation: fadeIn var(--onLoadAnimationTime) linear;
}
@keyframes fadeIn {
  0%, 15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hiddenForFadeIn {
  opacity: 0;
}
.projectName {
  text-align: center;
}

#contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.icon {
  text-decoration: none;
  color: var(--grey-color);
  font-size: 40px;
}

.projectImgWrapperBtn {
  border: none;
  background: inherit;
  height: fit-content;
  width: fit-content;
}

img.project {
  max-width: 100%;
  max-height: 600px;
  border-radius: 10px;
  margin: 0 auto;
  display: block;
  border: 1px rgb(170, 255, 177) solid;
  transition: 0.1s;
}
img.project:hover {
  transform: scale(1.01);
  border: 3px rgb(170, 255, 177) solid;
}
img.mobileProject {
  display: none;
}
#about {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0;
  width: calc(100% - 80px);
  border-bottom: 1px var(--grey-color) solid;
}
#about p {
  flex: 0 1 550px;
  text-align: justify;
  text-align-last: center;
}
#about a {
  text-decoration: none;
  color: blue;
}
#photo {
  flex: 0 1 400px;
  max-width: 400px;
  border-radius: 50%;
}

.modalBackDrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(10, 10, 10, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  max-width: 100%;
  width: 500px;
  max-height: 100%;
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  font-size: 2rem;
}

.modalCloseWrapper {
  display: flex;
  justify-content: flex-end;
}

.modalCloseWrapper .modalBtn {
  height: 4rem;
  width: 4rem;
  line-height: 1;
  border-radius: 2rem;
  padding: .5rem;
  margin: .5rem;
}

.modalBtn {
  padding: 1rem;
  border-radius: 2rem;
  background: linear-gradient(70deg, rgb(0, 45, 194), rgb(9, 153, 232));
  color: white;
  font-size: 2rem;
  border: none;
  text-decoration: none;
  font-family: inherit;
}

.modalContent {
  padding: 0 2rem .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width:1250px) {
  .individualProject:nth-child(n) {
    padding: 40px 10px;
  }
}
@media (max-width:1100px) {
  .individualProject:nth-child(n) {
    padding: 20px 5px;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: none;
  }
  .projectText {
    flex: auto;
  }
  img.project {
    margin: 10px 0;
  }
  #about {
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 0;
  }
  #about p {
    flex: auto;
    max-width: 550px;
    width: 95%;
  }
  #photo {
    flex: auto;
    max-width: 400px;
    width: 95%;
  }
}

@media (max-width:641px) {
  body {
    margin: 0;
  }
  .linkBtn {
    padding: 5px 3px;
  }
  img.mobileProject {
    display: block;
  }
  img.desktopProject {
    display: none;
  }
  .individualProject {
    margin: 0 5px;
    padding: 0;
  }
  .projectText {
    padding: 0 10px;
  }
  main {
    margin: 0 3px;
    width: calc(100% - 6px);
  }
  .floatUp {
    animation: none;
  }
  #about {
    padding: 0;
    margin: 0 5px;
    width: calc(100% - 40px);
  }
  #about p {
    width: 100%;
  }
}
